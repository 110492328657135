a.meet-link {
  text-decoration: none;
  width: 350px;
  background: $main-color;
  display: block;
  margin: 20px auto 0;
  padding: 10px 0;
  border-radius: 4px;
  color: $white-color;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
}

h2.meet-link-title {
  font-size: 1.5rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.334;
  letter-spacing: 0em;
  margin-top: 40px;
}

@media (max-width: 768px) {
  h2.meet-link-title {
    max-width: 80%;
    width: 80%;
    margin: 50px auto 20px;
  }

  span.child_h4_highlight {
    margin-bottom: 40px;
    display: block;
  }
}