.parent_div_loading {
  border-radius: 5px;
  padding: 5px;
  background-color: $secun-color;
  color: $white-color;
  width: 75%;
  max-width: 1500px;
  margin: auto;
  margin-top: 50px;
}

.child_h3_loading {
  padding: 5px;
}