@import '_variables.scss',
        'components/app-bar/index.scss',
        'components/loading/index.scss',
        'components/login/index.scss',
        'components/signin/index.scss',
        'components/edituser/index.scss',
        'components/dataTable/index.scss',
        'components/processed-payment/index.scss',
        'components/charge-account/index.scss',
        'pages/meet/index.scss',
        'pages/user/index.scss',
        'pages/home/highlight/index.scss';

body {
  margin: 0 auto;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.MuiAvatar-root.MuiAvatar-circular.MuiAvatar-colorDefault {
  background-color: $secun-color!important;
}

div#country {
  text-align: left;
}
