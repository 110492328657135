.content-data-table {
  max-width: 1200px;
  min-width: 400px;
  width: auto;
  margin: 50px auto 0;
}

thead.MuiTableHead-root,
th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignCenter.MuiTableCell-sizeSmall {
  background-color: $secun-color!important;
  height: 50px;
  font-size: 1.5rem!important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif!important;
  font-weight: 400!important;
  line-height: 1.334!important;
  letter-spacing: 0;
  margin: 10px;
  color: $white-color!important;
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignCenter.MuiTableCell-sizeSmall {
  height: 50px;
  font-size: 1.2rem!important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif!important;
  font-weight: 400!important;
  line-height: 1.334!important;
  letter-spacing: 0;
  margin: 10px;
}
