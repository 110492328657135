// Mismos estilos que en login

.span_show_password {
  float: left;
  color: #1976d2;
  margin-top: 15px;
  font-size: 0.9em;
  cursor: pointer;
}

.hide_password_icon {
  font-size: 0.83em;
}