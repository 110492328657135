

.mig-classes-main {
  width: auto !important;
  display: block !important; // Fix IE 11 issue.
  margin-left: 24px !important;
  margin-right: 24px !important;

  @media only screen and (min-width: 448px) {
    width: 400px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

}

.mig-classes-paper {
  margin-top: 64px !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center!important;
  padding: 16px 24px 24px !important;
}

.mig-classes-avatar {
  margin: 8px !important;
  //background-color: theme.palette.secondary.main,
}

.mig-classes-form {
  width: 100% !important; // Fix IE 11 issue.
  margin-top: 30px !important;
}

.mig-classes-submit {
  margin-top: 24px !important;
}

.login__link {
  margin-top: 24px !important;
  color: rgb(25, 118, 210);
  font-size: 14px;
}

.dialog-button {
  color: $white-color !important;
  background-color: $main-color !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  padding: 40px 30px 15px;
  max-width: 300px;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.dialog-button {
  width: 100%;
  margin-top: 30px;
}
