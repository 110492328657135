a.link-app-bar {
  color: white;
  font-size: 18px;
  margin: 10px;
  text-decoration: none;
}

.logo__link-app-bar {
  background: url('../../assets/logo.png');
  height: 52px;
  width: 200px;
  background-size: cover;
  background-repeat: no-repeat;
}

a.link-app-bar {
  color: $secun-color;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 600;
}

a.link-app-bar-manual  {
  text-decoration: none;
  margin-right: 15px;
  color: $secun-color;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 600;
}

a.MuiTypography-root.login__link {
  margin-top: 20px;
  font-size: 14px;
}

button.login__principal-buton {
  background: $main-color;
  color: $white-color;
}

.MuiButton-contained:hover {
  background: $main-color!important;
}

