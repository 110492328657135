.chargeaccount-main {
  width: auto !important;
  display: block !important; // Fix IE 11 issue.
  margin-left: 24px !important;
  margin-right: 24px !important;
  @media only screen and (min-width: 448px){
    width: 400px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.chargeaccount-paper {
  margin-top: 64px !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  padding: 16px 24px 24px !important;
}

.chargeaccount-avatar {
  width: 50px !important;
  height: 50px !important;
  margin: 8px !important;
  background-color: $main-color !important;
}

.chargeaccount-form {
  width: 100% !important; // Fix IE 11 issue.
  margin-top: 12px !important;
}

.chargeaccount-button {
  margin-top: 32px !important;
  background-color: $main-color !important;
  color: $white-color !important;
  &.Mui-disabled {
    background-color: rgba(0,0,0,0.12) !important;
    color: rgba(0,0,0,0.36) !important;
  }
}

.chargeaccount-formlabel {
  color: #333333 !important;
}

.chargeaccount-radio {
  color: #333333 !important;
}

.chargeaccount-formcontrollabel {
  margin: 0px !important;
}
.dueDate {
  text-align: center;
  padding-top: 0;
  margin-bottom: 20px;
  margin-top: 0;
  font-size: 0.9rem;
  color: $main-color !important;
}

.value {
  text-align: center;
  padding-top: 0;
  margin-bottom: 0;
  margin-top: 0;
  font-size: 0.9rem;
  color: $main-color !important;
}

.payment-loading {
  &.MuiCircularProgress-colorPrimary {
    color: $main-color !important;
    size: 0.5rem;
  }
}

.home-alert {
  background-color: $main-color !important;
}
