.edit_user_text {
  text-align: right;
  margin: 30px;
  margin-left: 10px;
  size: 1.2em !important;
}

.button-edit-user {
  color: $white-color !important;
  background-color: $main-color !important;
  width: 130px;
  height: 40px;
}